<template>
    <v-container fluid>
        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
            <v-card flat>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-row class="flex-nowrap">
                                <v-col cols="1" style="max-width: 80px">
                                    <v-btn :to="{name: 'dashboard'}" class="back-btn" link large>
                                        <v-icon>mdi-less-than</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="11">
                                    <v-subheader class="headline">{{ $t('to_issue_an_invoice_for_payment') }}</v-subheader>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row justify="center">

                        <v-col cols="12" md="3"  class="max-column">
                            <ValidationProvider ref="amount" rules="required|amount_symbol"
                                                v-slot="{ errors, valid }">
                                <v-text-field
                                    v-model="amount" type="text"
                                    class="centered-input font-size-30"
                                    readonly
                                    dense
                                    height="50px"
                                ></v-text-field>
                            </ValidationProvider>
                            <v-btn v-for="(number, index) in numbers"
                                   :key="index"
                                   elevation="2"
                                   text
                                   depressed
                                   height="50px"
                                   width="80px"
                                   class="my-2 mx-2 font-size-22"
                                   @click="keyPress(number)"
                            >{{ number }}</v-btn>
                            <v-btn
                                elevation="2"
                                text
                                depressed
                                height="50px"
                                width="80px"
                                class="my-2 mx-2 font-size-22 float-right mr-5"
                                @click="keyPressClear()"
                            >
                                <v-icon>
                                    mdi-backspace-outline
                                </v-icon>
                            </v-btn>
                            <v-btn
                                elevation="2"
                                text
                                depressed
                                height="50px"
                                width="80px"
                                class="my-2 mx-2 font-size-22  float-right"
                                @click="keyPress(0)"
                            >0</v-btn>

                        </v-col>

                    </v-row>
                    <v-row justify="center">
                        <v-col cols="12" md="3"  class="max-column">
                            <v-col class="py-0" cols="12" >
                                <ValidationProvider ref="phone" rules="required|phone"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="phoneRaw" type="tel" v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" append-icon="mdi-phone"
                                                  outlined
                                                  :label="$t('phone')" color="primary"
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-col>

                    </v-row>
                    <v-row justify="center">
                        <v-col cols="12" md="3" class="max-column-bottom">
                            <v-btn
                                color="primary"
                                block
                                elevation="2"
                                height="50px"
                                :disabled="invalid || loading"
                                :loading="loading"
                                @click="addInvoice(amount)"
                            >{{ $t('for_payment') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pt-6">
                    <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                       :active="loading" class="mx-2"></v-progress-linear>
                </v-card-actions>
            </v-card>
        </ValidationObserver>


        <v-dialog
            v-model="dialogAddInvoice"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >


            <v-card>
                <v-toolbar
                    elevation="0"
                >
                    <v-btn
                        icon

                        @click="cancelDialogInvoice()"
                    >
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-row  justify="center" class="mb-4 mt-16">
                    <div>{{ $t('an_invoice_has_been_issued_for_payment') }}:</div>
                </v-row>
                <v-row justify="center" class="mb-4 mt-5">
                    <span class="text-h3">{{totalPrice}}</span>
                </v-row>
                <v-row  justify="center" class="mb-2">
                    <div>{{ $t('account_number') }}: {{number}}</div>
                </v-row>
                <v-row  justify="center"  class="mb-2">
                    <div>{{$t('client')}} : {{ client_name }}</div>
                </v-row>
                <v-row  justify="center">
                    <div>{{  $t('phone') }} : {{phoneRaw}}</div>
                </v-row>

            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import {mask} from "vue-the-mask";

export default {
    name: 'Invoice',
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: null,
            show_languages: [],
            exist_translations: {},
            all_translations: true,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,
            phoneRaw: null,
            sum: null,

            numbers: [1,2,3,4,5,6,7,8,9],
            amount: "0 ₸",
            symbol: " ₸",
            dialogAddInvoice: false,
            client_name: null,
            number: null,
        }
    },
    computed: {
        ...mapGetters(['lang', 'phoneMask']),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },

    },
    methods: {
        async keyPress(number){
            if(this.amount.length < 10){
                if( this.amount === "0 ₸"){
                    if(number > 0){
                        this.amount =  number.toString() + this.symbol;
                    }
                }
                else {
                    this.amount = this.amount.slice(0, -2);
                    this.amount = this.amount.toString() + number.toString() + this.symbol;
                }
            }

        },
        async keyPressClear(){
            if(this.amount !== "0 ₸"){
                this.amount = this.amount.slice(0, -3);
                if(this.amount === ""){
                    this.amount = "0";
                }
                this.amount = this.amount + this.symbol;
            }
        },

        cancelDialogInvoice(){
            this.amount = "0 ₸";
            this.sum = null;
            this.phone = null;
            this.phoneRaw = null;
            this.client_name = null;
            this.number = null;
            this.dialogAddInvoice = false;

        },

        async addInvoice(amount) {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            let price = amount.slice(0, -2);
            price = (parseInt(price)).toLocaleString("ru-RU", {style: "currency", currency: "KZT", minimumFractionDigits: 0})
            price = price.toString().slice(0, -3);
            this.totalPrice =  price.toString() + this.symbol;

            this.sum = amount.slice(0, -2);

            if (this.sum) {
                formData.append('total', this.sum)
            }
            if (this.phone) {
                formData.append('phone', this.phone)
            }

            // Add
            await this.$http
                .post('partner/payment_invoice', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('invoice_has_been_added'));
                    this.client_name = res.body.data.client.name
                    this.number = res.body.data.number
                    this.dialogAddInvoice = true;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('invoice_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        }
    }
}
</script>

<style scoped>
.centered-input >>> input {
    text-align: center
}
.centered-input >>> .v-input__slot::before {
    border-style: none !important;
}
.v-text-field > .v-input__control > .centered-input:before{
    border-style: none !important;
}
.max-column{
    width: 325px !important;
    min-width:325px !important;
    max-width:325px !important;
}
.max-column-bottom{
    width: 300px !important;
    min-width:300px !important;
    max-width:300px !important;
}
</style>
